html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.42857;
  min-height: 100%;
  font-weight: 300;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  font-weight: 300;
}

label {
  font-weight: 600;
  margin: 0;
}

input,
select,
label,
button,
textarea {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.875rem;
}

p,
a {
  font-weight: 300;
}

ul {
  list-style: none;
  padding: 0;

}

strong {
  font-weight: bold;
}

@media (min-width: 641px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media (max-width: 641px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/*###########################################################################
# SCROLBAR
#############################################################################*/

.fancy-scrollbar::-webkit-scrollbar {
  width: 7.5px;
}

.fancy-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

.fancy-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/*###########################################################################
# GRID STRUCTURE
#############################################################################*/

.grid-block,
.grid-block-center {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.grid-block-center {
  align-items: center;
}
[class*="grid-block"]-center {
  align-items: center;
}
[class*="grid-block"] .span-sm-1 {
  grid-column: span 1;
}
[class*="grid-block"] .span-sm-2 {
  grid-column: span 2;
}
[class*="grid-block"] .span-sm-3 {
  grid-column: span 3;
}
[class*="grid-block"] .span-sm-4 {
  grid-column: span 4;
}
[class*="grid-block"] .span-sm-5 {
  grid-column: span 5;
}
[class*="grid-block"] .span-sm-6 {
  grid-column: span 6;
}
[class*="grid-block"] .span-sm-7 {
  grid-column: span 7;
}
[class*="grid-block"] .span-sm-8 {
  grid-column: span 8;
}
[class*="grid-block"] .span-sm-9 {
  grid-column: span 9;
}
[class*="grid-block"] .span-sm-10 {
  grid-column: span 10;
}
[class*="grid-block"] .span-sm-11 {
  grid-column: span 11;
}
[class*="grid-block"] .span-sm-12 {
  grid-column: span 12;
}

@media only screen and (min-width: 481px) {
  [class*="grid-block"] .span-sm-1 {
    grid-column: span 1;
  }
  [class*="grid-block"] .span-sm-2 {
    grid-column: span 2;
  }
  [class*="grid-block"] .span-sm-3 {
    grid-column: span 3;
  }
  [class*="grid-block"] .span-sm-4 {
    grid-column: span 4;
  }
  [class*="grid-block"] .span-sm-5 {
    grid-column: span 5;
  }
  [class*="grid-block"] .span-sm-6 {
    grid-column: span 6;
  }
  [class*="grid-block"] .span-sm-7 {
    grid-column: span 7;
  }
  [class*="grid-block"] .span-sm-8 {
    grid-column: span 8;
  }
  [class*="grid-block"] .span-sm-9 {
    grid-column: span 9;
  }
  [class*="grid-block"] .span-sm-10 {
    grid-column: span 10;
  }
  [class*="grid-block"] .span-sm-11 {
    grid-column: span 11;
  }
  [class*="grid-block"] .span-sm-12 {
    grid-column: span 12;
  }
}
@media only screen and (min-width: 641px) {
  [class*="grid-block"] .span-md-1 {
    grid-column: span 1;
  }
  [class*="grid-block"] .span-md-2 {
    grid-column: span 2;
  }
  [class*="grid-block"] .span-md-3 {
    grid-column: span 3;
  }
  [class*="grid-block"] .span-md-4 {
    grid-column: span 4;
  }
  [class*="grid-block"] .span-md-5 {
    grid-column: span 5;
  }
  [class*="grid-block"] .span-md-6 {
    grid-column: span 6;
  }
  [class*="grid-block"] .span-md-7 {
    grid-column: span 7;
  }
  [class*="grid-block"] .span-md-8 {
    grid-column: span 8;
  }
  [class*="grid-block"] .span-md-9 {
    grid-column: span 9;
  }
  [class*="grid-block"] .span-md-10 {
    grid-column: span 10;
  }
  [class*="grid-block"] .span-md-11 {
    grid-column: span 11;
  }
  [class*="grid-block"] .span-md-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1025px) {
  [class*="grid-block"] .span-lg-1 {
    grid-column: span 1;
  }
  [class*="grid-block"] .span-lg-2 {
    grid-column: span 2;
  }
  [class*="grid-block"] .span-lg-3 {
    grid-column: span 3;
  }
  [class*="grid-block"] .span-lg-4 {
    grid-column: span 4;
  }
  [class*="grid-block"] .span-lg-5 {
    grid-column: span 5;
  }
  [class*="grid-block"] .span-lg-6 {
    grid-column: span 6;
  }
  [class*="grid-block"] .span-lg-7 {
    grid-column: span 7;
  }
  [class*="grid-block"] .span-lg-8 {
    grid-column: span 8;
  }
  [class*="grid-block"] .span-lg-9 {
    grid-column: span 9;
  }
  [class*="grid-block"] .span-lg-10 {
    grid-column: span 10;
  }
  [class*="grid-block"] .span-lg-11 {
    grid-column: span 11;
  }
  [class*="grid-block"] .span-lg-12 {
    grid-column: span 12;
  }
}
